export default {
  Logo: {
    Url: "/img/pildyk_logo.png",
  },
  Confirmation: {
    Url: "/img/mascotts/mascott-10.png?v=2",
    Alt: "Ar tikrai?",
  },
  PlayStore: {
    Url: "/img/refills/play-store.png",
    Alt: "Google Play Store",
  },
  AppStore: {
    Url: "/img/refills/app-store.png",
    Alt: "App Store",
  },
  Success: {
    Url: "/img/mascotts/mascott-3.png?v=2",
    Alt: "Vasaros naktys sėkmingai užsakyta",
  },
  Error: {
    Url: "/img/mascotts/mascott-2.png?v=2",
    Alt: "Vasaros naktys klaida",
  },
  Avatar: {
    Url: "/img/default-avatar.png",
    Alt: "User Logo",
  },
  Empty: {
    Url: "/img/mascotts/mascott-4.png?v=2",
    Alt: "Empty",
  },
  RefillHeader: {
    Url: "/img/mascotts/mascott-7.png?v=2",
    Alt: "Sąskaitos papildymas",
  },
  Help: {
    Url: "/img/heroes/help.png",
    Alt: "Pagalba",
  },
  Newsletter: {
    Url: "/img/mascotts/mascott-9.png?v=3",
    Alt: "Naujienlaiškis",
  },
  HelpPage: {
    Url: "/img/mascotts/mascott-1.png?v=2",
    Alt: "pagalba",
  },
  ForeignCalls: {
    Url: "/img/plans/calls.png",
    Alt: "skambučiai",
  },
  P2pPlans: {
    Url: "/img/plans/p2pPlans.png",
    Alt: "planai",
  },
  NetworkMascott: {
    Url: "/img/mascotts/mascott-network.png?v=2",
    Alt: "Tinklas",
  },
  CookieAgreement: {
    Url: "/img/cookiebot/Cookie_mascott.png",
  },
  DiscountList: {
    Header: "/img/mascotts/404.png?v=2",
  },
  PromoBanner: {
    Url: "/img/plans/promo-banner.png",
    Alt: "Promo Banner",
  },
  SmsChallenge: {
    Url: "/img/mascotts/mascott-5.png?v=2",
    Alt: "Sms challenge",
  },
  Socials: {
    Facebook: {
      Url: "/img/socials/facebook_white.png",
      Alt: "Facebook icon",
    },
  },
  Reused: {
    Omniva: "/img/reused/omniva.png",
  },
  CustomSlides: {},
  LanguageFlags: {
    LT: "/img/languageFlags/LT.svg",
    EN: "/img/languageFlags/EN.svg",
    RU: "/img/languageFlags/RU.svg",
    UA: "/img/languageFlags/UA.svg"
  },
  AwardsCampaign: {
    SadMascott: "/img/awardsCampaign/sadMascott.png",
    MedalReceiveBackground: "/img/awardsCampaign/medalReceiveBackground.png",
    Notification: "/img/awardsCampaign/notification.png",
    Navigation: "/img/awardsCampaign/navigation.png",
    Profile: [
      "/img/awardsCampaign/profile/1.png",
      "/img/awardsCampaign/profile/2.png",
      "/img/awardsCampaign/profile/3.png",
      "/img/awardsCampaign/profile/4.png",
      "/img/awardsCampaign/profile/5.png",
      "/img/awardsCampaign/profile/6.png",
      "/img/awardsCampaign/profile/7.png",
      "/img/awardsCampaign/profile/8.png",
    ],
    Arrow: "/img/awardsCampaign/arrow.png",
    Steps: [
      "/img/awardsCampaign/step1.png",
      "/img/awardsCampaign/step2.png",
      "/img/awardsCampaign/step3.png",
    ],
    Consents: "/img/awardsCampaign/consents.png",
    BannerFirst: "/img/awardsCampaign/bannerFirst.png",
    BannerSecond: "/img/awardsCampaign/bannerSecond.png",
    Landing: {
      BodyBackgroundDesktop:
        "/img/awardsCampaign/landing1000Samsung/bodyBackgroundDesktop.png",
      BodyBackgroundMobile:
        "/img/awardsCampaign/landing1000Samsung/bodyBackgroundMobile.png",
      BodyRight: "/img/awardsCampaign/landing1000Samsung/bodyRight.png",
      Bottom: [
        "/img/awardsCampaign/landing1000Samsung/a53.png",
        "/img/awardsCampaign/landing1000Samsung/s22.png",
        "/img/awardsCampaign/landing1000Samsung/a13.png",
      ],
      BottomBackgroundDesktop:
        "/img/awardsCampaign/landing1000Samsung/bottomBackgroundDesktop.png",
      BottomBackgroundMobile:
        "/img/awardsCampaign/landing1000Samsung/bottomBackgroundMobile.png",
    },
    WheelBackground: "/img/awardsCampaign/wheel/wheelBackground.png",
    BannerBackgroundDesktop: "/img/awardsCampaign/bannerBackgroundDesktop.png",
    BannerBackgroundMobile: "/img/awardsCampaign/bannerBackgroundMobile.png",
    InfoBackground: "/img/awardsCampaign/infoBackground.png",
    SuccessBackground: "/img/awardsCampaign/successBackground.png",
    ConsentsBackground: "/img/awardsCampaign/consentsBackground.png",
  },
  SpecialOffers: [
    "/img/mascotts/mascott-3.png?v=2",
    "/img/mascotts/mascott-8.png?v=2",
    "/img/mascotts/mascott-11.png?v=2",
    "/img/mascotts/mascott-12.png?v=2",
  ],
  Narsyk: {
    UserNotFound: "/img/mascotts/mascott-13.png?v=2",
    UnknownError: "/img/mascotts/mascott-11.png?v=2",
  },
  ConsentCampaign: {
    Activated: "/img/consentsCampaign/consentsActivated.png",
    Consents: "/img/consentsCampaign/consents.png",
    SavedDesktop: "/img/consentsCampaign/consentsSavedDesktop.png",
    SavedMobile: "/img/consentsCampaign/consentsSavedMobile.png",
    Main: "/img/consentsCampaign/main.png",
    Settings: "/img/consentsCampaign/settings.png",
  },
  Whalebone: {
    Inactive: "/img/whalebone/inactive.png?v=2",
    Active: "/img/whalebone/active.png?v=2",
    Cancelled: "/img/whalebone/cancelled.png",
    Statistics: {
      Malicious: "/img/whalebone/malicious.png",
      Malware: "/img/whalebone/malware.png",
      Phishing: "/img/whalebone/phishing.png",
    },
    Filters: {
      Security: "/img/whalebone/filters/security.png",
      Adult: "/img/whalebone/filters/adult.png",
      Entertainment: "/img/whalebone/filters/entertainment.png",
      Crime: "/img/whalebone/filters/crime.png",
      Advertisement: "/img/whalebone/filters/advertisement.png"
    },
    Icons: {
      Big: "/img/whalebone/icon-70.png",
      Medium: "/img/whalebone/icon-50.png",
      Small: "/img/whalebone/icon-40.png",
    },
  },
  EarpodsCampaign: {
    Landing: {
      Header: {
        Background: {
          desktop: "/img/earpodsCampaign/2022/landing/headerBgDesktop.png",
          tablet: "/img/earpodsCampaign/2022/landing/headerBgTablet.png",
          mobile: "/img/earpodsCampaign/2022/landing/headerBgMobile.png",
        },
      },
      Slides: [
        "/img/earpodsCampaign/landing/1.png",
        "/img/earpodsCampaign/landing/2.png",
        "/img/earpodsCampaign/landing/3.png",
        "/img/earpodsCampaign/landing/4.png?v=2",
      ],
      Refill: {
        Background: "/img/earpodsCampaign/2022/landing/refillBackground.png",
        Image: "/img/earpodsCampaign/2022/landing/refill.png",
      },
      Items: {
        Jauti: {
          Background: {
            desktop: "/img/earpodsCampaign/2022/landing/jauti/bgDesktop.png",
            tablet: "/img/earpodsCampaign/2022/landing/jauti/bgTablet.png",
            mobile: "/img/earpodsCampaign/2022/landing/jauti/bgMobile.png",
          },
          Image: {
            desktop: "/img/earpodsCampaign/2022/landing/jauti/desktop.png",
            mobile: "/img/earpodsCampaign/2022/landing/jauti/mobile.png",
          },
          Behind: "/img/earpodsCampaign/2022/landing/jauti/behind.png",
        },
        Sk: {
          Background: {
            desktop: "/img/earpodsCampaign/2022/landing/sk/bgDesktop.png",
            tablet: "/img/earpodsCampaign/2022/landing/sk/bgTablet.png",
            mobile: "/img/earpodsCampaign/2022/landing/sk/bgMobile.png",
          },
          Image: {
            desktop: "/img/earpodsCampaign/2022/landing/sk/desktop.png",
            mobile: "/img/earpodsCampaign/2022/landing/sk/mobile.png",
          },
          Behind: "/img/earpodsCampaign/2022/landing/sk/behind.png",
        },
        Fsg: {
          Background: {
            desktop: "/img/earpodsCampaign/2022/landing/fsg/bgDesktop.png",
            tablet: "/img/earpodsCampaign/2022/landing/fsg/bgTablet.png",
            mobile: "/img/earpodsCampaign/2022/landing/fsg/bgMobile.png",
          },
          Image: {
            desktop: "/img/earpodsCampaign/2022/landing/fsg/desktop.png",
            mobile: "/img/earpodsCampaign/2022/landing/fsg/mobile.png",
          },
          Behind: "/img/earpodsCampaign/2022/landing/fsg/behind.png",
        },
      },
      FullPrice: "/img/earpodsCampaign/2022/landing/fullPrice.png",
      Footer: {
        Image: "/img/earpodsCampaign/2022/landing/footer.png?v=2",
        Background: {
          desktop: "/img/earpodsCampaign/2022/landing/footerBgDesktop.png",
          tablet: "/img/earpodsCampaign/2022/landing/footerBgTablet.png",
          mobile: "/img/earpodsCampaign/2022/landing/footerBgMobile.png",
        },
      },
    },
    Campaign: {
      Banner: "/img/earpodsCampaign/2022/banner.png?v=2",
      FirstStepHeader: "/img/earpodsCampaign/firstStepHeader.png?v=2",
      FsgPrize: "/img/earpodsCampaign/fsgPrize.png",
      SkPrize: "/img/earpodsCampaign/skPrize.png",
      JautiPrize: "/img/earpodsCampaign/jautiPrize.png",
      PurchaseSuccess: "/img/earpodsCampaign/2022/purchaseSuccess.png",
    },
  },
  DeviceInformation: {
    Header: "/img/mascotts/mascott-14.png?v=2",
  },
  ESimChange: {
    Banner: "/img/eSimChange/banner.png",
  },
  ESim: {
    Qr: {
      Mascott: "/img/eSimPurchase/mascott.png",
      Image: "/img/eSimPurchase/success.png",
      Background: "/img/eSimPurchase/successBackground.png",
    },
    Header: {
      normal: "/img/eSim/header.png?v=2",
      normalWebp: "/img/eSim/header.webp?v=2",
      small: "/img/eSim/smallHeader.png?v=2",
      smallWebp: "/img/eSim/smallHeader.webp?v=2",
    },
    Particles: {
      normal: "/img/eSim/particles.svg",
      small: "/img/eSim/smallParticles.svg",
    },
    Steps: {
      Step1: "/img/eSim/step1.svg",
      Step2: "/img/eSim/step2.svg",
      Step3: "/img/eSim/step3.svg",
    },
    LongerPeriod: {
      Step1: "/img/eSim/successStep1.svg",
      Step2: "/img/eSim/successStep2.svg",
    },
    ShowcasePhone: {
      Image: "/img/eSim/iphoneShowcase.png",
      WebpImage: "/img/eSim/iphoneShowcase.webp",
    },
    ShowcaseWatch: {
      Image: "/img/eSim/watchShowcase.png",
      WebpImage: "/img/eSim/watchShowcase.webp",
    }
  },
  RefillImages: {
    Animated: {
      Landing: "/animations/fonosCampaign/landing.json",
      BlurLoop: "/animations/fonosCampaign/blurLoop.json",
      Confetti: "/animations/fonosCampaign/confetti.json",
      Loop: "/animations/fonosCampaign/loop.json",
      LoopStart: "/animations/fonosCampaign/loopStart.json",
      Finish: "/animations/fonosCampaign/finish.json",
    },
    Dice: "/img/fonosCampaign/dice.png",
    EmojiOk: "/img/fonosCampaign/emojiOk.png",
    EmojiParty: "/img/fonosCampaign/emojiParty.png",
    FonosPhone: "/img/fonosCampaign/fonosPhone.png",
    Step1: "/img/fonosCampaign/Step1.png",
    Step2: "/img/fonosCampaign/Step2.png",
    Step3: "/img/fonosCampaign/Step3.png",
    Gradient: "/img/fonosCampaign/gradient.png",
    Card: "/img/fonosCampaign/card.png",
    ConfettiBanner: "/img/fonosCampaign/confettiBanner.png",
    BannerDesktop: "/img/fonosCampaign/bannerDesktop.png",
    BannerMobile: "/img/fonosCampaign/bannerMobile.png",
    GameGradient: "/img/fonosCampaign/gameGradient.png",
    Mascott3: "/img/fonosCampaign/mascott-3.png",
    AllPrizes: "/img/fonosCampaign/prizes/allPrizes.png",
    Backpack: "/img/fonosCampaign/prizes/backpack.png",
    Earbuds: "/img/fonosCampaign/prizes/earbuds.png",
    Phone: "/img/fonosCampaign/prizes/phone.png",
    Gb: "/img/fonosCampaign/prizes/gb.png",
    Jumper: "/img/fonosCampaign/prizes/jumper.png",
    Mask: "/img/fonosCampaign/prizes/mask.png",
    Mug: "/img/fonosCampaign/prizes/mug.png",
    Pin: "/img/fonosCampaign/prizes/pin.png",
    MouseFollow: "/img/fonosCampaign/mouseFollow.png",
  },
  SummerCampaign2023: {
    Banner: {
      Image: "/img/summerCampaign2023/banner-image.png?v=2"
    },
    Error: "/img/summerCampaign2023/error-ice-cream.png",
    Animated: {
      Lips: "/animations/summerCampaign2023/lupos.svg",
      IceCream: "/animations/summerCampaign2023/ledai.svg",
      Header: "/animations/summerCampaign2023/header.json",
      Eye: "/animations/summerCampaign2023/akis1.svg",
      TearyEye: "/animations/summerCampaign2023/akis2.svg",
      Crown: "/animations/summerCampaign2023/karuna.svg",
    },
    Header: {
      BrushDesktop: "/img/summerCampaign2023/brush-desktop.png",
      BrushMobile: "/img/summerCampaign2023/brush-mobile.png",
      Hero: "/img/summerCampaign2023/header-hero.png",
      Hero2: "/img/summerCampaign2023/header-hero-2.png",
      Profile: "/img/summerCampaign2023/profile-image.png",
      BrushBackground: "/img/summerCampaign2023/brush-logged-in.png",
    },
    Steps: {
      Step1: "/img/summerCampaign2023/step1.png",
      Step2: "/img/summerCampaign2023/step2.png",
      Step3: "/img/summerCampaign2023/step3.png",
      Step4: "/img/summerCampaign2023/step4.png",
    },
    Game:{
      Consents: "/img/summerCampaign2023/consent-background.png",
      Background: "/img/summerCampaign2023/game-background.png"
    },
    Gifts: {
      MeschinoGift: "/img/summerCampaign2023/gift-meschino.png",
      Star: "/img/summerCampaign2023/star.png",
      Flower: "/img/summerCampaign2023/flower.png",
      Sun: "/img/summerCampaign2023/sun.png",
      GiftArrow: "/img/summerCampaign2023/gift-arrow.png",
      GiftStart: "/img/summerCampaign2023/gift-start.png", 
      GiftEnd: "/img/summerCampaign2023/gift-end.png", 
      NextItems: "/img/summerCampaign2023/gift-next-items.png", 
    },
    Video: {
      Background: "/img/summerCampaign2023/video-background.png"
    },
    Question: {
      BrushDesktop: "/img/summerCampaign2023/black-brush-desktop.png",
      BrushMobile: "/img/summerCampaign2023/black-brush-mobile.png",
      Meschino: "/img/summerCampaign2023/question-meschino.png",
      Phrase: "/img/summerCampaign2023/question-phrase.png",
      Collab: "/img/summerCampaign2023/question-collab.png",
      RedStar: "/img/summerCampaign2023/question-red-star.png",
      BlueStar: "/img/summerCampaign2023/question-blue-star.png",
      BigWhiteStar: "/img/summerCampaign2023/question-big-white-star.png",
      LittleWhiteStar: "/img/summerCampaign2023/question-little-white-star.png",
      SuccessPage: "/img/summerCampaign2023/success-page.png",
    },
    Bottom: {
      ArrowRightDesktop: "/img/summerCampaign2023/arrow-right-desktop.png",
      ArrowLeftDesktop: "/img/summerCampaign2023/arrow-left-desktop.png",
      ArrowBottomDesktop: "/img/summerCampaign2023/arrow-down-desktop.png",
    }
  },
  WhyPildyk: {
    Banner: {
      LT: {
        NormalImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-normal-lt.png",
        SmallImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-small-lt.png",
      },
      EN: {
        NormalImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-normal-en.png",
        SmallImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-small-en.png",
      },
      RU: {
        NormalImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-normal-ru.png",
        SmallImage: "/img/whyPildyk/headerImages/why-pildyk-desktop-small-ru.png",
      },
    },
    NoObligations: {
      DesktopImage: "/img/whyPildyk/desktopImages/no-obligations.png",
      MobileImage: "/img/whyPildyk/mobileImages/no-obligations.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/no-obligations.png",
    },
    WeAreTheMostPopular: {
      DesktopImage: "/img/whyPildyk/desktopImages/we-are-the-most-popular.png?v=2",
      MobileImage: "/img/whyPildyk/mobileImages/we-are-the-most-popular.png?v=2",
      NavigationIcon: "/img/whyPildyk/navigationIcons/we-are-the-most-popular.png?v=2",
    },
    PildykTheCheapest: {
      DesktopImage: "/img/whyPildyk/desktopImages/pildyk-the-cheapest.png",
      MobileImage: "/img/whyPildyk/mobileImages/pildyk-the-cheapest.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/pildyk-the-cheapest.png",
    },
    WidestNetwork: {
      DesktopImage: "/img/whyPildyk/desktopImages/widest-network.png",
      MobileImage: "/img/whyPildyk/mobileImages/widest-network.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/widest-network.png",
    },
    FirstByPurchases: {
      DesktopImage: "/img/whyPildyk/desktopImages/first-by-purchases.png",
      MobileImage: "/img/whyPildyk/mobileImages/first-by-purchases.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/first-by-purchases.png",
    },
    MobilesForEuroInitialFee: {
      DesktopImage: "/img/whyPildyk/desktopImages/mobiles-for-euro-initial-fee.png",
      MobileImage: "/img/whyPildyk/mobileImages/mobiles-for-euro-initial-fee.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/mobiles-for-euro-initial-fee.png",
    },
    FunContent: {
      DesktopImage: "/img/whyPildyk/desktopImages/fun-content.png",
      MobileImage: "/img/whyPildyk/mobileImages/fun-content.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/fun-content.png",
    },
    WeWillAnswerAnyQuestions: {
      DesktopImage: "/img/whyPildyk/desktopImages/we-will-answer-any-questions.png",
      MobileImage: "/img/whyPildyk/mobileImages/we-will-answer-any-questions.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/we-will-answer-any-questions.png",
    },
    OurAppIsTheBest: {
      DesktopImage: "/img/whyPildyk/desktopImages/our-app-is-the-best.png",
      MobileImage: "/img/whyPildyk/mobileImages/our-app-is-the-best.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/our-app-is-the-best.png",
    },
    PildykUsersReceiveGifts: {
      DesktopImage: "/img/whyPildyk/desktopImages/pildyk-users-receive-gifts.png",
      MobileImage: "/img/whyPildyk/mobileImages/pildyk-users-receive-gifts.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/pildyk-users-receive-gifts.png",
    },
    WeAddToGoodWork: {
      DesktopImage: "/img/whyPildyk/desktopImages/we-add-to-good-work.png",
      MobileImage: "/img/whyPildyk/mobileImages/we-add-to-good-work.png",
      NavigationIcon: "/img/whyPildyk/navigationIcons/we-add-to-good-work.png",
    },
  },
  Dashboard: {
    Banner: "/img/mascotts/mascott-13.png?v=2"
  },
  Refill: {
    Hero1: {
      Png: "/img/refill/refill-hero-1.png",
      Webp: "/img/refill/refill-hero-1.webp",
    },
    Hero2: {
      Png: "/img/refill/refill-hero-2.png",
      Webp: "/img/refill/refill-hero-2.webp",
    },
    Hero3: {
      Png: "/img/refill/refill-hero-3.png",
      Webp: "/img/refill/refill-hero-3.webp",
    },
    HeroFailed: {
      Png: "/img/refill/refill-hero-failed.png",
      Webp: "/img/refill/refill-hero-failed.webp",
    },
    Campaign5G: {
      Png: "/img/refill/refill-hero-5g-campaign.png",
      Webp: "/img/refill/refill-hero-5g-campaign.webp",
      PngMobile: "/img/refill/refill-hero-5g-campaign-mobile.png",
    },
    Mascott15: {
      Png: "/img/mascotts/mascott-15.png?v=2",
    },
  },
};
