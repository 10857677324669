<template>
  <transition name="custom-classes-transition">
    <div
      class="popup--container"
      :class="[isOpen ? 'active' : 'popup--shadow---closed', customShadowClass]"
    >
      <div v-show="isOpen" class="popup--dialog-holder">
        <div
          v-if="isOpen"
          class="popup--dialog"
          :class="[
            {
              'popup--dialog---big': isBigPopup,
              'popup--dialog---small': isSmallPopup,
            },
            customClass,
          ]"
        >
          <div class="popup--close">
            <t2-svg icon-id="close" @click="emitClose" />
          </div>
          <div class="popup--content">
            <slot></slot>
          </div>
        </div>
      </div>
      <div class="popup--shadow" @click="emitClose"></div>
    </div>
  </transition>
</template>
<script>
import T2Svg from "@atomic/atoms/Svg";

export default {
  name: "T2Modal",
  components: {
    T2Svg,
  },
  inject: ["emitter"],
  props: {
    id: {
      type: String,
      default: ""
    },
    customClass: {
      type: [Array, Object, String],
      default: ""
    },
    customShadowClass: {
      type: [Array, Object, String],
      default: ""
    },
    isBigPopup: {
      default: false,
      type: Boolean,
    },
    isSmallPopup: {
      default: false,
      type: Boolean,
    },
    preventShadowClose: {
      default: false,
      type: Boolean
    }
  },
  emits: ["close"],
  data() {
    return {
      isOpen: false,
      scrolledY: 0,
    };
  },
  created() {
    this.emitter.on("open-modal", this.openModal);
    this.emitter.on("close-modal", this.closeModal);
  },
  methods: {
    openModal(payload) {
      if (payload.id === this.id) {
        this.isOpen = true;
        this.toggleScreenScroll();
      }
    },
    closeModal(payload) {
      if (payload.id === this.id) {
        this.isOpen = false;
        this.toggleScreenScroll();
      }
    },
    emitClose() {
      if (this.isOpen) {
        this.emitter.emit("close-modal", { id: this.id });
        this.$emit("close");
      }
    },
    toggleScreenScroll() {
      var scroll;
      if (window.scrollY !== undefined) {
        scroll = { x: window.scrollX, y: window.scrollY };
      } else {
        var sx;
        var sy;
        var d = document;
        var r = d.documentElement;
        var b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        scroll = { x: sx, y: sy };
      }
      if (this.isOpen) {
        this.scrolledY = scroll.y;
      }

      var elements = [
        document.querySelector("body"),
        document.querySelector("html"),
      ];
      for (var i = 0; elements.length > i; i++) {
        elements[i].style.margin = this.isOpen ? 0 : "";
        elements[i].style.position = this.isOpen ? "fixed" : "";
        elements[i].style.top = this.isOpen ? "-" + scroll.y + "px" : "";
      }
      if (!this.isOpen) {
        window.scrollTo(0, this.scrolledY);
      }
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/heading.less";
@import (reference) "~@/assets/less/paragraph.less";

.popup {
  &--shadow {
    background-color: fade(@color-black, 60);
    height: inherit;
    left: 0;
    opacity: 1;
    overflow-x: visible;
    padding: 0 33px 0;
    position: fixed;
    top: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    width: 100%;
    max-height: 100vh;
  }
  &--container {
    height: 100%;
    left: 0;
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    padding: 0 33px 0;
    position: fixed;
    top: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    width: 100%;
    max-height: 100vh;
    z-index: 900;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    @media screen {
      @media (min-width: @media-tablet-portrait) and (max-height: 530px) {
        align-items: flex-start;
      }
      @media (max-width: @media-tablet-portrait) {
        padding: 0;
      }
    }
  }
  &--dialog-holder {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 95%;
    min-height: 100%;
    margin: 0 auto;
    padding: 3% 0;
  }
  &--dialog {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-flow: column nowrap;
    margin: auto;
    padding: 33px;
    position: relative;
    background-color: @color-white;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 fade(@color-black, 40);
    transform: scale(1);
    transition: all 0.45s ease;
    opacity: 0.99;
    z-index: 901;
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        width: 100%;
        min-height: 400px;
        flex: 1 0 0;
        justify-content: center;
        padding: 33px 15px;
      }
    }
  }
  &--close {
    cursor: pointer;
    display: flex;
    flex-flow: row-reverse nowrap;

    svg {
      fill: @color-blue;
      height: 18px;
      width: 18px;
    }

    @media screen {
      @media (max-width: @media-mobile-portrait) {
        position: absolute;
        top: 33px;
        right: 15px;
      }
    }
  }
  &--content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 16px;
    line-height: 22px;
    padding: 28px 0;

    @media screen {
      @media (max-width: @media-mobile-portrait) {
        padding: 0;
      }
    }
  }
  &--heading {
    .heading--2;
    margin-top: 0;
    &---black {
      color: @color-black;
    }
  }
  &--sub-heading {
    .heading--3;
  }
  &--caption {
    .paragraph--content;
    text-align: justify;
    width: 100%;
    &---center {
      text-align: center;
    }
  }
  &--image-container {
    padding-top: 20px;
    max-width: 350px;
  }
  &--image {
    width: 100%;
  }
  &--main-icon {
    height: 100%;
    width: auto;
    &:nth-child(n + 2) {
      margin-left: 20px;
    }
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        &:not(:only-child) {
          height: 35px;
        }
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
      }
      @media (max-width: @media-mobile-small-portrait) {
        &:not(:only-child) {
          height: 30px;
        }
      }
    }
    &---wrapper {
      height: 5rem;
      @media screen {
        @media (max-width: @media-mobile-portrait) {
          & {
            height: auto;
          }
        }
        @media (max-width: @media-mobile-small-portrait) {
          & {
            height: auto;
          }
        }
      }
    }
  }
  &--total {
    display: flex;
    align-items: flex-end;
    margin-right: 2rem;
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        margin-right: 0;
        margin-bottom: 1rem;
        justify-content: center;
      }
    }
    &---price {
      .BP-bold;
      font-size: 44px;
      color: @color-blue;
      line-height: 46px;
      margin-bottom: 3px;
      &:not(:last-child) {
        margin-right: 1rem;
        @media screen {
          @media (max-width: @media-tablet-portrait) {
            margin-right: 0.5rem;
          }
        }
      }
      @media screen {
        @media (max-width: @media-tablet-portrait) {
          font-size: 44px;
          line-height: 40px;
          vertical-align: bottom;
        }
      }
    }
    &---suffix {
      .BP-bold;
      font-size: 22px;
      color: @color-blue;
    }
  }
  &--actions {
    display: flex;
    padding: 30px @grid-padding 0px;
    justify-content: center;
    &---right {
      margin-left: auto;
    }
    &---cta {
      @media screen {
        @media (max-width: @media-mobile-portrait) {
          display: flex;
          justify-content: center;
        }
      }
    }
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        padding: 40px (@grid-padding / 2) 0;

        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    &---multiple {
      .v-button:last-child {
        margin-left: 20px;
        @media screen {
          @media (max-width: @media-mobile-portrait) {
            margin-left: 10px;
          }
        }
      }
    }
  }
  &--actions-wrapper {
    display: flex;
    flex-flow: column nowrap;
    > .v-button {
      &:not([data-dialog-close]) {
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &[data-dialog-close] {
        margin-top: 20px;
      }
    }
  }
  &--multiple-cta {
    display: flex;
    margin: 0 auto;
    @media screen {
      @media (max-width: @media-mobile-small-portrait) {
        flex-direction: column;
      }
    }
    > .v-button {
      margin-right: 10px;

      @media screen {
        @media (max-width: @media-mobile-small-portrait) {
          margin-right: 0 !important;
          margin-bottom: 10px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &--cta {
    padding-top: 40px;
  }
  //modifiers
  &--dialog---big {
    max-width: 900px;
    padding-left: 30px;
    padding-right: 30px;
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  &--dialog---small {
    max-width: 400px;
  }
  &--dialog---plans {
    max-width: 700px;
  }
  &--caption---narrow {
    max-width: 350px;
  }
  &--shadow---closed {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    will-change: z-index, opacity;

    .popup--dialog {
      transform: scale(0.75);
      will-change: transform;
    }
  }

  &--shadow---closing {
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    will-change: z-index, opacity;

    .popup--dialog {
      transform: scale(0.75);
      will-change: transform;
    }
  }
  &--logo-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
</style>