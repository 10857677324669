<template>
  <transition name="custom-classes-transition">
    <div
      class="accordion"
      :class="[customClass, {
        'accordion---enabled': height || show,
        'accordion---mobile-only': mobileOnly
      }]"
      :style="{ '--max-height': currentHeight }"
      @transitionend="handleTransitionEnd"
    >
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  inject: ["emitter"],
  props: {
    id: {
      type: String,
      default: "accordion"
    },
    customClass: {
      type: [String, Object, Array],
      default: ""
    },
    show: {
      type: Boolean,
      default: false,
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      height: this.show ? "unset" : 0,
      suffix: "px",
      unsetValue: "unset",
    };
  },
  computed: {
    currentHeight() {
      return this.height === this.unsetValue
        ? this.unsetValue
        : this.height + this.suffix;
    }
  },
  created() {
    this.emitter.on(`toggle-accordion-${this.id}`, this.toggleContent);
    this.emitter.on(`reset-height-${this.id}`, this.setHeight);
  },
  mounted() {
    if (!this.show) {
      return;
    }

    return this.resetHeight();
  },
  beforeUnmount() {
    this.emitter.off(`toggle-accordion-${this.id}`, this.toggleContent);
    this.emitter.off(`reset-height-${this.id}`, this.setHeight);
  },
  methods: {
    handleTransitionEnd() {
      if (this.height > 0 && this.height !== this.unsetValue) {
        this.height = this.unsetValue;
      }
    },
    toggleContent() {
      requestAnimationFrame(() => {
        if (this.height === "unset") {
          this.height = this.$el.scrollHeight;
        }

        requestAnimationFrame(() => {
          this.height = this.height > 0 ? 0 : this.$el.scrollHeight;
        })
      });
    },
    setHeight() {
      return this.resetHeight();
    },
    resetHeight() {
      if (this.height === "unset") {
        return;
      }

      this.height = this.$el.scrollHeight;
    },
  },
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";

.accordion {
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  z-index: 0;
  height: 100%;
  max-height: var(--max-height);

  &---enabled {
    opacity: 1;
    z-index: 1;
  }
  &---mobile-only {
    max-height: unset;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-height: var(--max-height);
    }
  }
}
</style>
